import {Helmet} from "react-helmet";
import {PayPalButtons} from "@paypal/react-paypal-js";
import {useState} from "react";
import {Input, Notification, Stack} from 'rsuite';
import {BASIC_PLAN_ID} from "../constants/paypal";
import { useNavigate } from "react-router-dom"

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const SubscribePage = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState()
  const [error, setError] = useState()

  const isValidEmail = !!validateEmail(email)
  return <>
    <Helmet>
      <title>Softwaree | Subscribe</title>
    </Helmet>
    <div className="App">
      <header className="App-header">
        <h1 style={{marginBottom: 10}}>Subscribe</h1>
        <Input
          type="email"
          onChange={(val) => setEmail(val)}
          style={{marginBottom: 10, width: 300}}
          placeholder="enter your email address"
        />
        {
          isValidEmail &&
          <PayPalButtons
            style={{
              shape: 'pill',
              color: 'blue',
              layout: 'horizontal',
              label: 'paypal'
            }}
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                /* Creates the subscription */
                plan_id: BASIC_PLAN_ID,
                subscriber: {
                  email: email,
                }
              });
            }}
            onApprove={(data, actions) => {
              setSuccess(data)
              console.log(data)
              setTimeout(() => navigate("/"), 10000)
            }}
            onError={(err) => {
              console.error(err)
              setError(err)
            }}
          />
        }
      </header>
    </div>
    <Stack style={{position: "absolute", left: 0, top: 0, zIndex: 100}}>
      {
        success && success.subscriptionID &&
        <Notification
          style={{marginBottom: 10}}
          type="success"
          header="Success"
          closable onClose={() => navigate("/")}
        >
          Operation completed with subscription ID {success.subscriptionID}
        </Notification>
      }
      {
        error &&
        <Notification style={{marginBottom: 10}} type="error" header="Error" closable>
          Encountered an unexpected error. If this issue persists, contact us. Error details:<br/>{JSON.stringify(error)}
        </Notification>
      }
    </Stack>
  </>
}

export default SubscribePage;
