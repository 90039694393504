import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'rsuite/dist/rsuite.min.css';
import reportWebVitals from './reportWebVitals';
import {CustomProvider} from "rsuite";
import {RouterProvider} from "react-router-dom";
import router from "./App";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import {CLIENT_ID} from "./constants/paypal";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PayPalScriptProvider options={{
      clientId: CLIENT_ID,
      vault: true,
      intent: "subscription"
    }}>
    <CustomProvider theme="dark">
      <RouterProvider router={router}/>
    </CustomProvider>
    </PayPalScriptProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
