import React, {createRef} from 'react';
import {createBrowserRouter, useLocation, useOutlet} from 'react-router-dom';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import {Helmet} from 'react-helmet';
import MainPage from './pages/main';
import SubscribePage from './pages/subscribe';

const routes = [
  {path: '/', name: 'Home', element: <MainPage/>, nodeRef: createRef()},
  {
    path: '/subscribe',
    name: 'Subscribe',
    element: <SubscribePage/>,
    nodeRef: createRef(),
  },
]

function App() {
  const location = useLocation()
  const currentOutlet = useOutlet()
  const {nodeRef} = routes.find((route) => route.path === location.pathname) ?? {}
  return (
    <>
      <Helmet>
        <title>Toraphy</title>
      </Helmet>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={300}
          classNames="page"
          unmountOnExit
        >
          {(state) => (
            <div ref={nodeRef} className="page">
              {currentOutlet}
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </>
  );
}


const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>,
    children: routes.map((route) => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element,
    })),
  },
])

export default router;
