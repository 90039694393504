import logo from "../logo.svg";
import {Helmet} from "react-helmet";
import {Button, ButtonToolbar} from "rsuite";
import WhatsAppIcon from '@rsuite/icons/legacy/Whatsapp';
import MessengerIcon from '@rsuite/icons/legacy/Facebook';

const MainPage = () => {
  return (
    <>
      <Helmet>
        <title>Softwaree | Static Web Designs</title>
      </Helmet>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo"/>
          <h1>Get a website for $150/month</h1>
          <p>Click <a
            href="https://www.fiverr.com/s/AABE0B"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a> for more details</p>
          <ButtonToolbar
            style={{marginTop: 10}}
          >
            <Button
              startIcon={<MessengerIcon/>}
              color="blue"
              appearance="primary"
              onClick={() => window && window.open("https://m.me/alexangzy", "_blank")}
            >
              Messenger
            </Button>
            <Button
              startIcon={<WhatsAppIcon/>}
              color="green"
              appearance="primary"
              onClick={() => window && window.open("https://wa.me/6588016782", "_blank")}
            >
              WhatsApp
            </Button>
          </ButtonToolbar>
        </header>
      </div>
    </>
  )
}

export default MainPage;
